// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-tack-index-js": () => import("./../../../src/pages/tack/index.js" /* webpackChunkName: "component---src-pages-tack-index-js" */),
  "component---src-templates-avveckla-aktiebolag-index-js": () => import("./../../../src/templates/avveckla-aktiebolag/index.js" /* webpackChunkName: "component---src-templates-avveckla-aktiebolag-index-js" */),
  "component---src-templates-basic-page-index-js": () => import("./../../../src/templates/basic-page/index.js" /* webpackChunkName: "component---src-templates-basic-page-index-js" */),
  "component---src-templates-blog-list-page-index-js": () => import("./../../../src/templates/BlogListPage/index.js" /* webpackChunkName: "component---src-templates-blog-list-page-index-js" */),
  "component---src-templates-blog-post-index-js": () => import("./../../../src/templates/blog-post/index.js" /* webpackChunkName: "component---src-templates-blog-post-index-js" */),
  "component---src-templates-contact-page-index-js": () => import("./../../../src/templates/contact-page/index.js" /* webpackChunkName: "component---src-templates-contact-page-index-js" */),
  "component---src-templates-homepage-index-js": () => import("./../../../src/templates/homepage/index.js" /* webpackChunkName: "component---src-templates-homepage-index-js" */),
  "component---src-templates-kopa-lagerbolag-index-js": () => import("./../../../src/templates/kopa-lagerbolag/index.js" /* webpackChunkName: "component---src-templates-kopa-lagerbolag-index-js" */)
}

